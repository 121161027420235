/* / Set a max width for the chat container and position it in the center / */
.react-chatbot-kit-chat-container {
    background-color: #fff !important;
    max-width: 640px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.7px solid #cccccc;
    border-radius: 12px;
    max-height: inherit;
    border: 1px solid #6937D3 !important;
    box-shadow: 0 0 4px #0000001a;
    border-radius: 20px;
    height: calc(100% - 30px);
    overflow: hidden;
    margin: 0px auto;
    min-height: 96vh;
}
.options-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .option-button {
    background-color: #6937D3;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .option-button:hover {
    background-color: #6937D3;
  }

  form.react-chatbot-kit-chat-input-form {
    border: 1px solid #cccccc;
    overflow: hidden;
    min-height: 47px;
}

  .react-chatbot-kit-chat-input-container {
    position: absolute;
    bottom: 28px;
    top: inherit !important;
    width: 100%;
    display: flex;
    z-index: 9999999;
    height: 75px;
    background-color: #FFF;
}
/* / Adjust inner container styles /
/ .react-chatbot-kit-chat-inner-container { /
    / background-color: lightblue; /

    / Add vertical scrollbar if needed /
    / max-height: calc(70vh - 20px); /
    / Adjust height to fit within container minus padding and border /
    / overflow-y: auto; /

/ } /

/ Adjust inner container styles / */
.react-chatbot-kit-chat-inner-container {
    background-color: white !important;
    overflow-y: hidden;
    min-height: 81vh;
    height: auto;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}

/* / Adjust avatar styles / */
.react-chatbot-kit-chat-bot-avatar-letter {
    color: #000;
}

.react-chatbot-kit-chat-bot-avatar-letter{
    display: none;
    /* background-image: url('../img/purple.svg'); */
}



/* / Chat bot avatar container / */
.react-chatbot-kit-chat-bot-avatar-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    background: url('../img/purple.svg') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* / Chat bot avatar icon / */
.react-chatbot-kit-chat-bot-avatar-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    /* / Adjust the margin-right / */
}

/* / Chat bot message / */
.react-chatbot-kit-chat-bot-message {
    border: 1px solid #e5f9ff;
    background: #e5f9ff;
    /* / background-color: #2898ec; / */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 470px;
    color: #000;
    font-weight: 400;
    margin-left: 10px;
    margin-bottom: 10px;
    background-color: #6937d31f;
    /* / Adjust the margin-left / */
}

.menu-text {
    color: #000;
    width: 100%;
    height: auto;
    border-radius: 0;
    margin: 0 auto;
    background: none;
    border: none;
    padding: 0;
}



.header-icon-img-box {
    position: relative;
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 82px;
    margin-top: 14px;
}
.header-icon-img-box .bot-icon {
    /* position: absolute;
    left: 10px;
    top: -74px;
    float: left; */
    display: inline-flex;
    align-items: center;
    width: 45px;
    object-fit: cover;
    margin-left: 25px;
}
.card-header.chatHeader .logoStyle {
    margin-left: 0;
    margin-top: 1px;
}

.logoStyle {
    padding: 1px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 0;
    margin: 0;
}

.fot-link{color: #6937D3;
    text-decoration: none;
    background-color: transparent;}

.react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    overflow: auto !important;
    height: 70vh !important;
    margin-top: 80px;
    max-height: 558px;
}
.react-chatbot-kit-user-chat-message {
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #e5f9ff !important;
    background: #e5f9ff !important;
    box-shadow: inherit;
    border-radius: 10px;
}
.react-chatbot-kit-chat-input{
    outline: 0;
    box-shadow: inherit;
    padding: 0px 5px 0px 23px;
    height: 100%;
    border: 0;
}

.react-chatbot-kit-user-chat-message-arrow {
    width: 0;
    height: 0;
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;
    border-left: 0px solid #f1f1f1 !important;
    position: absolute;
    right: -7px;
    top: 13px;
}

.react-chatbot-kit-chat-bot-message-arrow{
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #e5f9ff;
    position: absolute;
    left: -7px;
    top: 13px;
}

/* / Default button styles / */
.option-button {
    background-color: #ffffff;
    color: #000000;
    border: 0.7px solid #cccccc;
    padding: 8px 16px;
    margin: 4px;
    cursor: pointer;
    border-radius: 12px;
    font-size: 13px;
}

/* / Selected button styles / */
.option-button.selected {
    background-color: #6937D3 !important;
    color: #ffffff;
}

.footer-section{
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 7px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    box-sizing: border-box;
}

.App{ 
    margin: 0;
    position: relative;
    padding: 0 !important; 
}

/* / Chat header / */
.react-chatbot-kit-chat-header {
    border-radius: 19px 19px 0 0;
    box-shadow: 0px 2px 4px #ccc;
    padding: 1rem 0rem 1rem 5.8rem;
    background: #6937D3 !important;
    color: #FFF !important;
    z-index: 999999;
    font-size: 1.3rem;
    font-weight: 300;
    position: fixed;
    width: 100%;    
    height: 50px;
    display: flex;
    max-width: 100%;
}











/* SLIDER-CUSTOM START*/

.rating_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    padding: 0 52px;
}
.rating_form .debt-amount-slider {
    width: 100%;
}

.rating_form .debt-amount-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 50px;
    user-select: none;
}
.rating_form .debt-amount-slider::before {
    content: " ";
    position: absolute;
    height: 2px;
    width: 100%;
    width: calc(100% * (4 / 5));
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #DFDFDF;
}
.rating_form .debt-amount-slider input, .rating_form .debt-amount-slider label {
    box-sizing: border-box;
    flex: 1;
    user-select: none;
    cursor: pointer;
}
.rating_form .debt-amount-slider label {
    display: inline-block;
    position: relative;
    width: 20%;
    height: 100%;
    user-select: none;
}
.rating_form .debt-amount-slider label::before {
    content: attr(data-debt-amount);
    position: absolute;
    left: 50%;
    padding-top: 0px;
    transform: translate(-50%, 45px);
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0.85;
    transition: all 0.15s ease-in-out;
}
.rating_form .debt-amount-slider label::after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 2px solid #DFDFDF;
    background: #fff;
    border-radius: 50%;
    pointer-events: none;
    user-select: none;
    z-index: 1;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}
.rating_form .debt-amount-slider label:hover::after {
    transform: translate(-50%, -50%) scale(1.25);
}
.rating_form .debt-amount-slider input {
    display: none;
}
.rating_form .debt-amount-slider input:checked + label::before {
    font-weight: 700;
    color: #6937D3;
    opacity: 1;
}
.rating_form .debt-amount-slider input:checked + label::after {
    border: 4px solid #6937D3;
    transform: translate(-51%, -49%) scale(0.75);
}
.rating_form .debt-amount-slider input:checked ~ .debt-amount-pos {
    opacity: 1;
}
.rating_form .debt-amount-slider input:checked:nth-child(1) ~ .debt-amount-pos {
    left: 7.02%;
}
.rating_form .debt-amount-slider input:checked:nth-child(3) ~ .debt-amount-pos {
    left: 21.45%;
}
.rating_form .debt-amount-slider input:checked:nth-child(5) ~ .debt-amount-pos {
    left: 35.7%;
}
.rating_form .debt-amount-slider input:checked:nth-child(7) ~ .debt-amount-pos {
    left: 49.9%;
}
.rating_form .debt-amount-slider input:checked:nth-child(9) ~ .debt-amount-pos {
    left: 64.2%;
}
.rating_form .debt-amount-slider input:checked:nth-child(11) ~ .debt-amount-pos {
    left: 78.6%;
}
.rating_form .debt-amount-slider input:checked:nth-child(13) ~ .debt-amount-pos {
    left: 92.8%;
}
.rating_form .debt-amount-slider .debt-amount-pos {
    display: block;
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    background: #6937D3;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    opacity: 0;
    z-index: 2;
}
.rating_form:valid .debt-amount-slider input + label::before {
    transform: translate(-50%, 45px) scale(0.9);
    transition: all 0.15s linear;
}
.rating_form:valid .debt-amount-slider input:checked + label::before {
    transform: translate(-50%, 45px) scale(1.1);
    transition: all 0.15s linear;
}
.topLabels .aligLab{
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    word-break: break-word;
    width: 85px;
    line-height: 13px;
}
.topLabels {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    max-width: 476px;
}
.topLabels .aligLab:nth-child(2){ text-align: center; }

.topLabels .aligLab:last-child{ text-align: end; }

.radio_slider-custom {  margin: 10px 0 30px; }





/* / SLIDER-CUSTOM END / */

.react-chatbot-kit-chat-btn-send{
    background: transparent;
    outline: 0;
    box-shadow: inherit;
    border: 0;
}

.react-chatbot-kit-chat-btn-send-icon{
    fill: #949494;
    width: 23px;
    margin: 0 auto;
}

















/* / Media query for responsiveness / */
@media (max-width: 767px) {
    .react-chatbot-kit-chat-container {
        background-color: #fff !important;
        max-width: 100%;
        width: calc(100% - 40px);
        margin: 0;
    }
    .header-icon-img-box .bot-icon {
        margin-left: 40px;
    }
    .header-icon-img-box{ max-width: 100%; }
}