.startbtn{
    border: none;
    width: 99px;
    height: 34px;
    color: white;
    background-color: #6937D3;
    border-radius: 3px;
    cursor: pointer;
}

.start-button-container {
  display: flex;
  justify-content: center; /* Horizontally centers the button */
  align-items: center; /* Vertically centers the button */
  /* height: 100vh; Full height to vertically center within the viewport */
}

/* .startbtn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
} */
